<template>
    <div class="text-center h-36 flex flex-col justify-end pb-4">
        <p class="text-sm">* Portfolio created with <a href="https://vuejs.org/" target="_blank"><span class="text-teal-200">Vue.js</span></a> & <a href="https://tailwindcss.com/" target="_blank"><span class="text-teal-200">Tailwind</span></a></p>
        <p class="text-sm">Portfolio <a href="https://github.com/BrianDLara/Portfolio" target="_blank"><span class="text-teal-200">Repo</span></a> </p>
        <p class="text-sm">© Brian Lara 2023</p>
    </div>
</template>

<script>
    export default {
        name: 'PageFooter',
    }
</script>