<template>
    <div>
      <h1 class="section-title text-center text-5xl mb-2 py-4 underline">
        Projects 
      </h1>
      <div class="flex flex-col items-center justify-center text-center">
        <!-- Project 1 -->
        <div class="project-container flex justify-evenly items-center flex-col xl:flex-row px-10 py-10 xl:py-4 xl:mb-20 xl:mt-10 xl:px-2 xl:px-8 xl:pb-16">
          <!-- image -->
          <div> 
            <a href="https://magical-meals.herokuapp.com/" target="_blank"><img src="https://i.imgur.com/hhxkpaS.png" alt="" class="project-img my-4 mx-auto lg:mx-2 xl:mx-6 border rounded"></a>
            <p class="text-cyan-600 mb-6">*Page deployed on Heroku. Please be patient with loading time</p>
          </div>
          <!-- icons -->
          <div>
            <div>
              <p class="max-w-3xl py-4 px-6 lg:px-2 xl:px-24 text-start"><span class="text-secondary-color">Description: </span>Experience the magic of Disney cooking in your own kitchen with Movie inspired recipes. Avoid the frustration of not having the right ingredients on hand. Simply purchase the required items directly from the website and have them delivered to you for a seamless and enjoyable cooking experience.</p>
              <p class="max-w-3xl py-4 px-6 lg:px-2 xl:px-24 text-start"><span class="text-secondary-color">Problem: </span>Finding fun recipes to make online, but never following thought because you don't purchase them at the grocery store.</p>
              <p class="max-w-3xl py-4 px-6 lg:px-2 xl:px-24 text-start"><span class="text-secondary-color">Solution: </span>The website lets you purchase the items directly and gets them to your house, so you can just focus on cooking.</p>
            </div>
            <div class="flex justify-center my-4">
              <!-- React logo -->
              <img class="devicon-devicon-plain mx-2" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg" />
              <!-- css logo -->
              <img class="devicon-devicon-plain mx-2" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original-wordmark.svg" />
              <!-- javascript logo -->
              <img class="devicon-devicon-plain mx-2" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg" />
              <!-- tailwind logo -->
              <img class="devicon-devicon-plain mx-2" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/tailwindcss/tailwindcss-plain.svg" />
              <!-- sequelize logo -->
              <img class="devicon-devicon-plain mx-2" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sequelize/sequelize-original.svg" />
              <!-- express logo -->
              <img class="white-logo devicon-devicon-plain mx-2" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/express/express-original.svg" />
              <!-- postgreSQL logo -->
              <img class="devicon-devicon-plain mx-2" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/postgresql/postgresql-original.svg" />
            </div>
            <div class="my-8">
              <a href="https://github.com/BrianDLara/Magical-Meals_Frontend" target="_blank"><button class="inline-block text-lg px-6 py-4 mx-4 leading-none border rounded text-white border-white custom__button__project">Github Repo</button></a>
              <a href="https://magical-meals.herokuapp.com/" target="_blank"><button class="inline-block text-lg px-6 py-4 mx-4 leading-none border rounded text-white border-white custom__button__project">Website</button></a>
            </div>
          </div>
        </div>
        <img src="https://i.imgur.com/3hhfbsg.png" alt="project divider" class="project-divider xl:hidden py-10" width="300px">

        <!-- Project 2 -->
        <div class="project-container flex justify-evenly items-center text-center flex-col  xl:flex-row-reverse px-10 py-10 xl:mb-20 xl:py-4 lg:px-2 xl:px-8 lg:py-16">
          <!-- image -->
          <a href="https://halloween-tic-tac-toe.surge.sh/" target="_blank"><img src="https://i.imgur.com/nd4yeB9.png" alt="" class="project-img my-4 mx-auto lg:mx-2 xl:mx-6 border rounded"></a>
          <div> 
            <div>
              <p class="max-w-3xl py-4 px-6 lg:px-2 xl:px-24 text-start"><span class="text-secondary-color">Description: </span>Halloween theme Tic-Tac-Toe game with logic that lets the computer play against you.</p>
              <p class="max-w-3xl py-4 px-6 lg:px-2 xl:px-24 text-start"><span class="text-secondary-color">Problem: </span>Nowadays it's so easy to create websites with libraries like react, that you overlook the fundamentals and core concepts that actually make javascript amazing.</p>
              <p class="max-w-3xl py-4 px-6 lg:px-2 xl:px-24 text-start"><span class="text-secondary-color">Solution: </span>I created a fully responsive game with pure vanilla javascript and css</p>
            </div>

            <!-- icons -->
            <div class="flex justify-center my-4">
              <!-- HTML logo -->
              <img class="devicon-devicon-plain mx-2" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg" />

              <!-- css logo -->
              <img class="devicon-devicon-plain mx-2" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original-wordmark.svg" />
              <!-- javascript logo -->
              <img class="devicon-devicon-plain mx-2" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg" />
            </div>
            <div class="my-8">
              <a href="https://github.com/BrianDLara/tic-tac-toe" target="_blank"><button class="inline-block text-lg px-6 py-4 mx-4 leading-none border rounded text-white border-white custom__button__project">Github Repo</button></a>            
              <a href="https://halloween-tic-tac-toe.surge.sh/" target="_blank"><button class="inline-block text-lg px-6 py-4 mx-4 leading-none border rounded text-white border-white custom__button__project">Website</button></a>

            </div>
          </div>
          
        </div>
        
        <img src="https://i.imgur.com/3hhfbsg.png" alt="project divider" class="project-divider xl:hidden py-10" width="300px">

        <!-- Project 3 -->
        <div class="project-container flex justify-evenly items-center flex-col xl:flex-row px-10 py-10 xl:mb-20 xl:py-4 lg:px-2 xl:px-8 lg:py-16">
          <div>
            <a href="https://main.dyzqsxxtteyld.amplifyapp.com/" target="_blank"><img src="https://i.imgur.com/iclfkh5.png" alt="" class="project-img my-4 mx-auto lg:mx-2 xl:mx-6 border rounded"></a>
            <p class="text-cyan-600 mb-6">*Page deployed on Heroku. Please be patient with loading time</p>
          </div>
          
          <div>
            <div>
              <p class="max-w-3xl py-4 px-6 lg:px-2 xl:px-24 text-start"><span class="text-secondary-color">Description: </span>School website where you can see all the courses that the students are taking with their average GPA.</p>
              <p class="max-w-3xl py-4 px-6 lg:px-2 xl:px-24 text-start"><span class="text-secondary-color">Problem: </span>The challenge was to enhance my teamwork skills, particularly in the areas of effective communication and thorough documentation practices. The goal was to ensure that anyone who worked on the application would have a clear understanding of its components and be able to make contributions seamlessly.</p>
              <p class="max-w-3xl py-4 px-6 lg:px-2 xl:px-24 text-start"><span class="text-secondary-color">Solution: </span>I took part in a 30-hour Hackathon and worked with a team of three to develop a full-stack application. This experience not only allowed me to enhance my technical abilities, but also significantly improved my communication and team collaboration skills.</p>
            </div>
            <div class="flex justify-center my-4">
              <!-- React logo -->
              <img class="devicon-devicon-plain mx-2" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg" />
              <!-- css logo -->
              <img class="devicon-devicon-plain mx-2" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original-wordmark.svg" />
              <!-- javascript logo -->
              <img class="devicon-devicon-plain mx-2" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg" />
              <!-- bootstrap logo -->
              <img class="devicon-devicon-plain mx-2" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-original.svg" />

              <!-- sequelize logo -->
              <img class="devicon-devicon-plain mx-2" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sequelize/sequelize-original.svg" />
              <!-- express logo -->
              <img class="white-logo devicon-devicon-plain mx-2" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/express/express-original.svg" />
              <!-- postgreSQL logo -->
              <img class="devicon-devicon-plain mx-2" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/postgresql/postgresql-original.svg" />
            </div>
            <div class="my-8">
              <a href="https://github.com/BrianDLara/student-registry-frontend" target="_blank"><button class="inline-block text-lg px-6 py-4 mx-4 leading-none border rounded text-white border-white custom__button__project">Github Repo</button></a>
              <a href="https://main.dyzqsxxtteyld.amplifyapp.com/" target="_blank"><button class="inline-block text-lg px-6 py-4 mx-4 leading-none border rounded text-white border-white custom__button__project">Website</button></a>
            </div>
            
          </div>
          
        </div>

        <img src="https://i.imgur.com/3hhfbsg.png" alt="project divider" class="project-divider xl:hidden py-10" width="300px">


        <!-- Project 4 -->
        <div class="project-container flex justify-evenly items-center flex-col xl:flex-row-reverse px-10 py-10 xl:mb-20 xl:py-4 lg:px-2 xl:px-8 lg:py-16">
          <div>
            <a href="https://valormore.herokuapp.com/" target="_blank"><img src="https://i.imgur.com/tykIlaQ.png" alt="" class="project-img my-4 mx-auto lg:mx-2 xl:mx-6 border rounded"></a>
            <p class="text-cyan-600 mb-6">*Page deployed on Heroku. Please be patient with loading time</p>
          </div>
          
          <div>
            <div>
              <p class="max-w-3xl py-4 px-6 lg:px-2 xl:px-24 text-start"><span class="text-secondary-color">Description: </span>Created an e-commerce website that lets you purchase different items, and leave product reviews.</p>
              <p class="max-w-3xl py-4 px-6 lg:px-2 xl:px-24 text-start"><span class="text-secondary-color">Problem: </span>Create a webpage with a beautiful interface without the use of external libraries like Bootstrap and tailwind</p>
              <p class="max-w-3xl py-4 px-6 lg:px-2 xl:px-24 text-start"><span class="text-secondary-color">Solution: </span>I created a responsive website with only vanilla css to further understand what makes a good website for the average user.</p>
            </div>
            <div class="flex justify-center my-4">
              <!-- React logo -->
              <img class="devicon-devicon-plain mx-2" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg" />
              <!-- css logo -->
              <img class="devicon-devicon-plain mx-2" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original-wordmark.svg" />
              <!-- javascript logo -->
              <img class="devicon-devicon-plain mx-2" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg" />
              <!-- node js logo -->
              <img class="devicon-devicon-plain mx-2" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg" />

              <!-- mongoDB logo -->
              <img class="devicon-devicon-plain" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-original.svg" />
              <!-- express logo -->
              <img class="white-logo devicon-devicon-plain mx-2" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/express/express-original.svg" />
            </div>
            <div class="my-8">
              <a href="https://github.com/BrianDLara/Valormore" target="_blank"><button class="inline-block text-lg px-6 py-4 mx-4 leading-none border rounded text-white border-white custom__button__project">Github Repo</button></a>
              <a href="https://valormore.herokuapp.com/" target="_blank"><button class="inline-block text-lg px-6 py-4 mx-4 leading-none border rounded text-white border-white custom__button__project">Website</button></a>
            </div>
          </div>
          
        </div>

        <img src="https://i.imgur.com/3hhfbsg.png" alt="project divider" class="project-divider xl:hidden py-10" width="300px">


         <!-- Project 5 -->
         <div class="project-container flex justify-evenly items-center flex-col xl:flex-row px-10 py-10  xl:py-4 lg:px-2 xl:px-8 lg:py-16">
          <a href="http://space-fighters.surge.sh/" target="_blank"><img src="https://i.imgur.com/cMuwxq6.png" alt="" class="project-img my-4 mx-auto lg:mx-2 xl:mx-6 border rounded"></a>
          <div>
            <div>
              <p class="max-w-3xl py-4 px-6 lg:px-2 xl:px-24 text-start"><span class="text-secondary-color">Description: </span>Space invaders look-a-like game, with score counter and border detection.</p>
              <p class="max-w-3xl py-4 px-6 lg:px-2 xl:px-24 text-start"><span class="text-secondary-color">Problem: </span>Create a game without the help of javascript libraries</p>
              <p class="max-w-3xl py-4 px-6 lg:px-2 xl:px-24 text-start"><span class="text-secondary-color">Solution: </span>Created a functional space invaders game with only HTML, Javascript, and CSS</p>
            </div>
            <!-- <p class="py-4 px-6 lg:px-2 xl:px-24 self-center">Space invaders look-a-like game, with score counter and border detection.</p> -->
            <div class="flex justify-center my-4">
              <!-- HTML logo -->
              <img class="devicon-devicon-plain mx-2" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg" />

              <!-- css logo -->
              <img class="devicon-devicon-plain mx-2" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original-wordmark.svg" />
              <!-- javascript logo -->
              <img class="devicon-devicon-plain mx-2" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg" />
            </div>
            <div class="my-8">
              <a href="https://github.com/BrianDLara/Space-Fighters" target="_blank"><button class="inline-block text-lg px-6 py-4 mx-4 leading-none border rounded text-white border-white custom__button__project">Github Repo</button></a>
              <a href="http://space-fighters.surge.sh/" target="_blank"><button class="inline-block text-lg px-6 py-4 mx-4 leading-none border rounded text-white border-white custom__button__project">Website</button></a>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </template>
  
  <script>
   export default {
      name: 'UserProjects'
    }
  </script>
  